@import url("https://fonts.googleapis.com/css2?family=PT+Mono&display=swap");

$breakpoints: (
    "small": 767px,
    "medium": 992px,
    "large": 1200px,
) !default;

/// Mixin to manage responsive breakpoints
/// @author Kitty Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin respond-to($breakpoint) {
    // If the key exists in the map
    @if map-has-key($breakpoints, $breakpoint) {
        // Prints a media query based on the value
        @media (min-width: map-get($breakpoints, $breakpoint)) {
            @content;
        }
    }

    // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
            + "Available breakpoints are: #{map-keys($breakpoints)}.";
    }
}

body {
    background-color: #000080;
    color: whitesmoke;
    a {
        color: whitesmoke;
        &:visited {
            color: whitesmoke;
        }
    }
}

.samcrochet {
    display: flex;
    flex-direction: column;
    font-weight: 100;
    padding-top: 2rem;
    font-size: 16px;
    font-family: "PT Mono", monospace;
    font-weight: bold;

    a {
        // background: linear-gradient(45deg, rgba(245, 245, 245, 0.5), rgb(0, 0, 128));
        background-color: rgba(245, 245, 245, 0.5);
        width: min-content;
        padding: 0 .5rem 0 .5rem;
        border-radius: .5rem;
    }

    .content {
        width: 90%;
        @include respond-to(medium){
            width: 50%;
        }
        @include respond-to(large) {
            width: 40%;
        }
    }

    button {
        font-weight: bold;
        border-width: 1rem;
        color: whitesmoke;
    }

    hr { 
        color: whitesmoke;
    }

    .headline {
        margin: 1rem;
        text-align: center;
        padding: 1rem;
    }

    .header {
        padding: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        &__left {
            font-size: 2rem;
        }

        &__right{
            display: flex;
            flex-direction: column;

            &--mobile-disclaimer {
                font-size: .6rem;
                font-style: italic;
            }
        }
    }

    .dot {
        height: 10px;
        width: 10px;
        background-color: red;
        border-radius: 50%;
        display: inline-block;
        animation: blink 1.2s linear infinite;
        margin-right: 0.5rem;
    }

    .track {
        margin: 1rem;
    }

    .body {
        font-size: 18px;
        margin: 1rem;

        &__headline {
            font-weight: 400;
        }

        &__line {
            padding-bottom: 0.5rem;
        }
    }
}
